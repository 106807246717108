var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-6"},[_c('v-container',[_c('h1',{staticClass:"mb-7 mt-4 title-bg text-vi"},[_vm._v(_vm._s(_vm.$t("all_categories")))]),(_vm.categories.length)?_c('v-row',_vm._l((_vm.categories),function(category,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6"}},[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"image","height":"235"}})],1):_c('v-card',{staticClass:"pa-6",attrs:{"outlined":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('h3',{staticClass:"mb-3"},[_c('router-link',{staticClass:"text-reset primary--text",attrs:{"to":{
                    name: 'Category',
                    params: { categorySlug: category.slug },
                  }}},[_vm._v(_vm._s(category.name))])],1),(category.children.data.length)?_c('div',_vm._l((category.children.data),function(children,j){return _c('v-hover',{key:j,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('router-link',{class:[
                      'text-reset me-1 opacity-80',
                      { 'primary--text text-decoration-underline': hover },
                    ],attrs:{"to":{
                      name: 'Category',
                      params: { categorySlug: children.slug },
                    }}},[_vm._v(_vm._s(children.name)),(j + 1 !== category.children.data.length)?_c('span',{},[_vm._v(",")]):_vm._e()])]}}],null,true)})}),1):_vm._e()]),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{staticClass:"text-reset",attrs:{"to":{
                  name: 'Category',
                  params: { categorySlug: category.slug },
                }}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.addResourceImage(category.banner),"alt":category.name},on:{"error":function($event){return _vm.imageFallback($event)}}})])],1)],1)],1)],1)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }